import { message } from 'antd';
import QrReader from 'react-qr-reader';

type PropsType = {
    onScan: (text: string) => void
}

export default function QrScanner({ onScan }: PropsType) {
    const onScannerRead = (data: string | null) => {
        if (data) {
            const url = new URL(data);
            const siteId = url.searchParams.get('site-id');            
            if (siteId) {
                onScan(siteId);
            }
            else {
                message.error('Invalid QR code')
            }
        }
    }

    return (
        <QrReader
            delay={1000}
            onError={(e: any) => console.log(e)}
            onScan={onScannerRead}
            style={{ width: "100%" }}
            showViewFinder
            facingMode="environment"
        />
    );
}