import React, { useContext, useEffect, useState } from 'react';
import { GoogleMap, Marker, Polygon, useJsApiLoader } from '@react-google-maps/api';
import { checkInToSite, checkOutFromSite, convertCoordinates, getCheckedInDetails, getOrgLocations, isComplaintUser } from './actions';
import { Button, Card, Col, FloatButton, Modal, Row, message } from 'antd';
import { isPointInPolygon } from 'geolib'
import { authContext } from '../../context/auth';
import { Loading } from '../../components';
import Timer from './components/Timer';
import { ApiConfig } from '../../configs/config';
import { QrcodeOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../constants';
import { Location } from '../../types';

export default function Sites() {
    const [locations, setLocations] = useState<any>([]);
    const [isLoading, setLoading] = useState<boolean>(true);
    const [checkedIn, setCheckedIn] = useState<any>(null);
    const [map, setMap] = useState<any>(null);
    const [current, setCurrent] = useState<any>();
    const [checkintime, setCheckintime] = useState<Date>(new Date());
    const [showMarker, setShowMarker] = useState<boolean>(true);
    const navigate = useNavigate();
    const { authState } = useContext(authContext);
    const { selectedOrg, loading, user } = authState;
    useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: ApiConfig.mapKey,
        libraries: ['drawing'],
        language: 'en',
        region: 'aus'
    });

    const getLocationsData = async () => {
        if(selectedOrg) {
            const { org_id } = selectedOrg;
            const locations: Location[] = await getOrgLocations([org_id]);
            const siteMapLocations = await convertCoordinates(locations);
            setLocations(siteMapLocations);
        }
    }

    const calculateOffsetTime = (time: string) => {
        setCheckintime(new Date(time));
    }

    const getCheckedInData = async () => {
        const { user_id } = user;
        const { data } = await getCheckedInDetails(user_id);
        if (data) {
            calculateOffsetTime(data.checkin_time)
        }
        setCheckedIn(data);
        setLoading(false);
    }

    const onAllowedLocationPermission = () => {
        navigator.geolocation.getCurrentPosition((position: any) => {
            setCurrent({ lat: position.coords.latitude, lng: position.coords.longitude });
            setShowMarker(true);
        });
        Modal.destroyAll();
    }

    const askLocationPermission = () => {
        Modal.info({
            icon: null,
            title: <img className='location-permission-logo' src='/logos/contractor.png'/>,
            content: 
                <>
                    <h2 className='font-family location-permission-title'>
                        Use your location
                    </h2>
                    <p className='font-family location-permission-content'>
                        This app will use your location even when the app is closed or not in use
                    </p>
                    <p className='font-family location-permission-content'>
                        <ul>
                            <li>To track your location whilst only in the facility to find you in case of an incident</li>
                            <li>To allow you to check in and check out of a facility</li>
                            <li>To notify hazards and incidents around you</li>
                        </ul>
                    </p>
                </>,
            okButtonProps: { className: 'site-modal-button' },
            footer: <>
                <Button className='location-permission-btn'  onClick={() => onAllowedLocationPermission()}>Allow Permission</Button>
            </>
        });
    }

    const getCurrentLocation = async () => {
        const permission = navigator.permissions && navigator.permissions.query && await navigator.permissions.query({ name: "geolocation" });
        
        if (permission) {
            const { state } = permission;
            if (state === "granted") {
                navigator.geolocation.getCurrentPosition((position: any) => {
                    setCurrent({ lat: position.coords.latitude, lng: position.coords.longitude });
                    setShowMarker(true);
                });
            } else if (state === "prompt") {
                askLocationPermission();
            } 
            else if (state === "denied") {
                message.warning('Location permission denied');
            }
        } else {
            askLocationPermission();
        }

        if (!current) {
            setShowMarker(false);
            setCurrent({ lat: -28.772700, lng: 134.077834 })
        }
    }

    const getSIteIdFromUrl = () => {
        const params =  new URLSearchParams(window.location.search);
        return params.get('siteId');
    }

    const isQrCheckIn = () => {
        const siteId = getSIteIdFromUrl();
        if (siteId && locations.length > 0) {
            const location = locations.find((loc: any) => loc.locationId === siteId);            
            if (location) {
                onAutoCheckIn(location);
            } else {
                message.error('You are not authorized to checkin');
            }
        }
    }

    useEffect(() => {
        if (!loading) {
            getLocationsData();
            getCurrentLocation();
            getCheckedInData();
        }
    }, [loading, selectedOrg]);

    useEffect(() => {
            isQrCheckIn();
    }, [locations]);

    const checkIn = async (orgId: string, ouId: string, userName: string, userId: string, contractorId: string) => {
        await checkInToSite(orgId, ouId, contractorId, userName, userId, current.lng, current.lat);
        await getCheckedInData();
        message.success('You have checked in successfully');
    }

    const checkOut = async () => {
        if (checkedIn) {
            message.loading('Checking out...')
            const { org_id } = selectedOrg;
            const { user_id, contractor_id, first_name, last_name } = user;
            await checkOutFromSite(org_id, checkedIn.ou_id, contractor_id, `${first_name} ${last_name}`, user_id, current.lng, current.lat);
            await getCheckedInData();
            message.destroy();
            message.success('You have checked out successfully');
        }
    }

    const onCheckOut = () => {
        Modal.confirm({
            style: { top: '75vh' },
            title: <p className='font-family'>Are you sure you want to check out ?</p>,
            okButtonProps: { className: 'site-modal-button' },
            onOk: () => checkOut(),
            okText: 'Yes'
        });
    }

    const onAutoCheckIn = async (location: any) => {
        message.loading('Please wait...');
        const { org_id } = selectedOrg;
        const { user_id, contractor_id, first_name, last_name } = user;
        const complaint = await isComplaintUser(org_id, location.ouId, user_id, contractor_id);
        message.destroy();

        if (!complaint) {
            Modal.confirm({
                style: { top: '60vh' },
                closable: true,
                title: <p className='font-family'>You are not complaint...</p>,
                content: <p className='font-family'>
                            Contractor requirements, both General and Site Specific, 
                            must be completed before being allowed onsite. Please address 
                            the Requirements before proceeding onsite.
                        </p>,
                footer: null
            });
            return;
        }

        if (checkedIn) {
            Modal.confirm({
                style: { top: '75vh' },
                closable: true,
                title: <p className='font-family'>You have already checked in...</p>,
                content: <p className='font-family'>
                            You have already checked into another site, please check out from it first
                        </p>,
                footer: null
            });
        }
        else {
            Modal.confirm({
                style: { top: '54vh' },
                title: <p className='font-family'>Are you sure..?</p>,
                okButtonProps: { className: 'site-modal-button' },
                content: <>
                            <p className='font-family'>
                                Are you sure, you want to check into the facility {location.ouName}?
                                <br/>
                                I am aware that I am checking in outside of the designated check in area of the facility. 
                                Once checked in my location will be tracked. Location tracking will cease when checked out.
                            </p>
                        </>,
                onOk: () => checkIn(org_id, location.ouId, `${first_name} ${last_name}`, user_id, contractor_id),
                okText: 'Yes'
            });
        }
    }

    const onPolygonLoad = async (location: any) => {
        if (!checkedIn) {
            const currentLocationInSite = await isPointInPolygon(current, location.paths);
            const siteId = getSIteIdFromUrl();
            if (currentLocationInSite && !siteId) {
                onAutoCheckIn(location);
            }
        }
    }

    const Polygons = (locations: any) => (
        <>
            {locations.map((location: any) => (
                <Polygon
                    onLoad={() => onPolygonLoad(location)}
                    key={location.locationId}
                    options={{
                        fillColor: location.fillColor,
                        strokeColor: '#565657',
                        strokeOpacity: 0.2,
                        fillOpacity: 0.6,
                        paths: location.paths
                    }}
                    onClick={() => onAutoCheckIn(location)}
                />
            ))}
        </>
    )

    const onLoad = React.useCallback(function callback(map: any) {
        setMap(map);
    }, []);

    const onUnmount = React.useCallback(function callback(map: any) {
        setMap(null);
    }, []);

    return (
        <>
            {isLoading ?
                <Loading /> :
                <>
                    <GoogleMap
                        mapContainerClassName='map-class'
                        center={current}
                        zoom={10}
                        onLoad={onLoad}
                        onUnmount={onUnmount}
                    >
                        {Polygons(locations)}
                        {showMarker && <Marker position={current} />}
                    </GoogleMap>
                    {checkedIn ?
                        <Row className='checkout-modal'>
                            <Card className='checkout-card' bodyStyle={{ padding: 20 }}>
                                <Row align='bottom'>
                                    <Col span={16}>
                                        <p className='checkout-title'>{checkedIn.name}</p>
                                        <Timer initTime={checkintime} />
                                    </Col>
                                    <Col span={8}>
                                        <Button className='checkout-btn full-width' type='default' onClick={() => onCheckOut()}>Check-out</Button>
                                    </Col>
                                </Row>
                            </Card>
                        </Row>
                    :   <FloatButton icon={<QrcodeOutlined className='qr-floating-icon' />} onClick={() => navigate(ROUTES.SCAN_QR)} type='primary' className='qr-floating-btn'/>
                    }
                </>
            }
        </>
    )
}