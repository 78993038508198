import { signIn, signOut, fetchAuthSession } from 'aws-amplify/auth';
import { ACTIONS, DispatchType } from '../../context/auth';
import { ContractorApi, getApi } from '../../utils';

export const userSignIn = async (username: string, password: string, dispatch: DispatchType) => {
    try {
        const { isSignedIn } = await signIn({ username, password });
        await initialData(dispatch);
        return {
            isSignedIn,
            response: 'Login success'
        };
    }
    catch (error: any) {
        console.log(error);
        return {
            response: error.message,
            isSignedIn: false
        }
    }
}

export const userSignOut = async (dispatch: DispatchType) => {
    try {
        await signOut();
        dispatch({ type: ACTIONS.USER_SIGNED_IN, payload: null });
        return true;
    }
    catch (error: any) {
        console.log(error);
        throw error.message;
    }
}

const getUserData = async (dispatch: DispatchType) => {
    try {
        const userData = (await fetchAuthSession())?.tokens?.idToken?.payload.userData?.toString();
        const user = userData && JSON.parse(userData);
        dispatch({ type: ACTIONS.USER_SIGNED_IN, payload: user });
    }
    catch (error: any) {
        console.log(error);
        throw error;
    }
}

const getUserOrgs = async (dispatch: DispatchType) => {
    try {
        const url = `/org_list`;
        const { orgList } = await getApi(ContractorApi.USER_API, url);
        const selectedOrg = orgList.length > 0 ? orgList[0] : null;
        dispatch({ type: ACTIONS.ORG_LIST, payload: orgList });
        dispatch({ type: ACTIONS.SELECTED_ORG, payload: selectedOrg });
    }
    catch (error: any) {
        console.log(error);
        throw error;
    }
}

export const initialData = async (dispatch: DispatchType) => {
    try {
        await getUserData(dispatch);
        await getUserOrgs(dispatch);
        dispatch({ type: ACTIONS.LOADING, payload: false });
        dispatch({ type: ACTIONS.AUTHENTICATED, payload: true });
    }
    catch (error: any) {
        console.log(error);
        throw error;
    }
} 