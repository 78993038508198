import { Amplify } from "aws-amplify";
import { AwsConfig, ApiConfig } from "../configs/config";
import { ConnectApi, ContractorApi } from "./api";

const { cognitoUserPoolId, userPoolWebClientId, cognitoIdentityPoolId } = AwsConfig;
const { basePath, connectApiBasePath, stage } = ApiConfig;

export const awsConfigInit = async () => {
    try {
        await Amplify.configure({
            Auth: {
                Cognito: {
                    identityPoolId: cognitoIdentityPoolId,
                    userPoolId: cognitoUserPoolId,
                    userPoolClientId: userPoolWebClientId,
                }
            },
            API: {
                REST: {
                    [ContractorApi.DOCUMENT_API]: {
                        endpoint: `${basePath}/${stage}-document-api/${stage}`
                    },
                    [ContractorApi.EXTERNAL_API]: {
                        endpoint: `${basePath}/${stage}-external-api`
                    },
                    [ContractorApi.REQUIREMENT_API]: {
                        endpoint: `${basePath}/${stage}-req-api`
                    },
                    [ContractorApi.USER_API]: {
                        endpoint: `${basePath}/${stage}-user-api/${stage}`
                    },
                    [ConnectApi.LOCATION_API]: {
                        endpoint: `${connectApiBasePath}/${stage}-location-api/${stage}`
                    },
                }
            }
        });
    }
    catch (error: any) {
        console.log(error);
        throw error;
    }
}
