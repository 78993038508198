import React, { useContext, useEffect, useState } from 'react';
import { TabsProps } from 'antd';
import RequirementTabBody from './components/RequirementTabBody';
import { Loading, TabViewLayout } from '../../components';
import { useSearchParams } from 'react-router-dom';
import { getGeneralRequirements, getSiteRequirements, getUserRequirements } from './actions';
import { authContext } from '../../context/auth';
import { GeneralRequirement, Requirements, SiteRequirements, UserRequirement } from '../../types';

export default function RequirementsView() {
    const [isLoading, setLoading] = useState<boolean>(true);
    let [searchParams, setSearchParams] = useSearchParams();
    const [requirements, serRequirements] = useState<Requirements>({ userRequirements: undefined, generalRequirements: undefined, siteSpecificRequirements: undefined });
    const { authState } = useContext(authContext);
    const { selectedOrg, user, loading } = authState;

    const getRequirements = async (orgId: string, userId: string, contractorId: string) => {
        setLoading(true);
        const userRequirements: UserRequirement[] = await getUserRequirements(orgId, contractorId, userId);
        const generalRequirements: GeneralRequirement[] = await getGeneralRequirements(orgId);
        const siteSpecificRequirements: SiteRequirements[] = await getSiteRequirements(orgId);
        serRequirements({
            userRequirements,
            generalRequirements,
            siteSpecificRequirements
        });
        setLoading(false);
    }

    const loadData = async () => {
        const { org_id } = selectedOrg;
        const { user_id, contractor_id } = user;
        await getRequirements(org_id, user_id, contractor_id);
    }

    useEffect(() => {
        if (!loading && selectedOrg) {
            loadData();
        }
    }, [loading, selectedOrg]);


    const tabItems: TabsProps['items'] = [
        {
            key: 'own',
            label: <span className='tab-title'>My</span>,
            children: <RequirementTabBody requirements={requirements.userRequirements} />
        },
        {
            key: 'general',
            label: <span className='tab-title'>General</span>,
            children: <RequirementTabBody requirements={requirements.generalRequirements} />
        },
        {
            key: 'site-specific',
            label: <span className='tab-title'>Site Specific</span>,
            children: <RequirementTabBody requirements={requirements.siteSpecificRequirements} />
        }
    ];

    const activeTab = () => {
        const tab = searchParams.get('tab')
        if (tabItems) {
            if (tab && tabItems?.find(item => item.key === tab)) return tab
            return tabItems[0].key
        }
    }
    const handleTabChange = (key: string) => setSearchParams({ tab: key })

    const setloadingContent = () => {
        if(!selectedOrg) {
            return (<div className='warning-message'><p> This contractor does not have any active organization.</p></div>);
        } else {
            return (<Loading />);
        }
    }

    return (
        <>
            {isLoading ?
                setloadingContent() :
                <TabViewLayout
                    tabs={tabItems}
                    defaultActiveTabKey='active' activeKey={activeTab()}
                    onTabChange={handleTabChange}
                />
            }
        </>

    )
}