import { fetchAuthSession } from "aws-amplify/auth";
import { ApiConfig } from "../configs/config";
import { get, post } from "aws-amplify/api";

export const ContractorApi = {
    EXTERNAL_API: 'externalAPI',
    DOCUMENT_API: 'documentAPI',
    REQUIREMENT_API: 'requirementAPI',
    USER_API: 'userAPI',
};

export const ConnectApi = {
    LOCATION_API: 'locationAPI'
};

export const getApi = async (apiName: string, path: string, params?: any): Promise<any> => {
    try {
        const token = (await fetchAuthSession())?.tokens?.idToken?.toString();
        const options = {
            ...params,
            headers: {
                Authorization: token
            }
        };
        const data = get({ apiName, path, options });
        return await (await data.response).body.json();
    }
    catch (error) {
        console.log(error);
        throw new Error(`${error}`);
    }
}

export const postApi = async (apiName: string, path: string, params?: any): Promise<any> => {
    try {
        const token = (await fetchAuthSession())?.tokens?.idToken?.toString();
        const options = {
            ...params,
            headers: {
                Authorization: token
            }
        };
        const data = post({ apiName, path, options });
        return await (await data.response).body.json();
    }
    catch (error) {
        console.log(error);
        throw new Error(`${error}`);
    }
}

export const getApiPath = (path: string): string => `${ApiConfig.basePath}/${path}`;