import { GeneralRequirement, SiteRequirements, UserRequirement } from "../../types";
import { ContractorApi, getApi } from "../../utils";

export const getUserRequirements = async (orgId: string, contractorId: string, userId: string): Promise<UserRequirement[]> => {
    const url = `/org_user_reqs?orgId=${orgId}&contractorId=${contractorId}&userId=${userId}`
    return await getApi(ContractorApi.EXTERNAL_API, url);
}

export const getGeneralRequirements = async (orgId: string): Promise<GeneralRequirement[]> => {
    const url = `/org_general_requests?orgId=${orgId}`
    return await getApi(ContractorApi.DOCUMENT_API, url);
}

export const getSiteRequirements = async (orgId: string): Promise<SiteRequirements[]> => {
    const url = `/site_requirements?orgId=${orgId}`
    return await getApi(ContractorApi.REQUIREMENT_API, url);
}
