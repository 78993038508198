import React, { useEffect, useState } from 'react';

const Timer = ({ initTime }: any) => {
    const [count, setCount] = useState(0);
    const [time, setTime] = useState("00:00:00");

    const showTimer = (s: number) => {
        const second = Math.floor((s / 1000) % 60)
            .toString()
            .padStart(2, "0");
        const minute = Math.floor((s / 1000 / 60) % 60)
            .toString()
            .padStart(2, "0");
        const hour = Math.floor(s / 1000 / 60 / 60).toString().padStart(2, "0");;
        setTime(
            hour + ":" + minute + ":" + second
        );
    };

    useEffect(() => {
        setInterval(() => {
            const left = count + (new Date().getTime() - initTime.getTime());
            setCount(left);
            showTimer(left);
            if (left <= 0) {
                setTime("00:00:00");
            }
        }, 1000);
    }, []);

    return (
        <div>
            <p className='checkout-timer-text'>Time since check-in</p>
            <p className='checkout-timer'>{time}</p>
        </div>
    );
}

export default Timer;