import { Space, Tabs, TabsProps } from 'antd';
import React, { ReactNode } from 'react';

type PropsType = {
    title?: ReactNode,
    tabs: TabsProps['items'],
    defaultActiveTabKey: string
    activeKey?: TabsProps['activeKey']
    onTabChange?: TabsProps['onChange']
}

function ScrolledTabBody({ children }: { children: ReactNode }) {
    return (
        <Space.Compact className='full-width tabbed scroll-view pad-all'>
            {children}
        </Space.Compact>
    )
}

export default function TabViewLayout({ tabs, defaultActiveTabKey, activeKey, onTabChange }: PropsType) {

    const tabItems = tabs?.map((tab) => (
        {
            ...tab,
            children: (<ScrolledTabBody>{tab.children}</ScrolledTabBody>)
        }
    ));

    return (
        <div className='full-width content-centered full-height'>
            <div className='full-height '>
                <Space.Compact direction='vertical' className='full-width full-height'>
                    <Tabs className='tab-view-layout' defaultActiveKey={defaultActiveTabKey} items={tabItems} activeKey={activeKey} onChange={onTabChange} />
                </Space.Compact>
            </div>
        </div>
    );
}