import React from 'react';
import { Flex, Layout } from 'antd';
import { Navigate, Route, Routes } from 'react-router-dom';
import { ROUTES } from '../../constants';
import { Requirements } from '../Requirements';
import { Sites } from '../Sites';
import { ScanQrView } from '../ScanQr';
import SideNavigator from './SideNavigator';

const { Header, Content } = Layout;

export default function MainView() {

    return (
        <Layout className='full-width full-height'>
            <Header className='flex-header pad-hori content-centered' >
                <Flex justify='space-between' align='center' className='full-width logo-wrapper'>
                    <SideNavigator />
                    <Flex justify='center' align='center' className='full-width'>
                        <img className='logo-header content-centered' alt='contractor_logo' src='/logos/contractor.png' />
                    </Flex>
                </Flex>
            </Header>
            <Content className='content-height'>
                <Routes>
                    <Route path={ROUTES.REQUIREMENTS} element={<Requirements />} />
                    <Route path={ROUTES.SITES} element={<Sites />} />
                    <Route path={ROUTES.SCAN_QR} element={<ScanQrView />} />
                    <Route path="*" element={<Navigate to={ROUTES.REQUIREMENTS} />} />
                </Routes>
            </Content>
        </Layout>
    )
}