import React, { useCallback, useContext, useEffect, useState } from 'react';
import { SmileTwoTone } from '@ant-design/icons';
import { Button, Space, Typography, notification } from 'antd';
import { isIOS } from 'react-device-detect';
import { ACTIONS, authContext } from '../../context/auth';

const NOTI_KEY = 'app-install-req-noti';

export default function AppInstallRequester() {
    const [deferredPrompt, setDiferredPrompt] = useState<Event | null>(null);
    const [notiApi, contextHolder] = notification.useNotification();
    const { dispatch, authState } = useContext(authContext);
    const { appInstallRequested } = authState;

    const onLaterClick = useCallback(() => {
        notiApi.destroy(NOTI_KEY);
    }, [notiApi]);

    const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);

    const onInstallClick = useCallback(() => {
        try {
            if (deferredPrompt) {
                (deferredPrompt as any).prompt();
            }
        } catch (e) {
            console.log(e);
        } finally {
            onLaterClick();
        }
    }, [deferredPrompt, onLaterClick]);

    const request = useCallback(() => {
        if (isIOS) {
            notiApi.open({
                placement: 'bottom',
                key: NOTI_KEY,
                message: <b>Install epar Connect Contractor</b>,
                description: 
                    <Typography.Text>
                        Install <b>epar Connect Contractor</b> on your home screen for quick and easy access when you are on the go.<br/>
                        <br/>
                        <b>To install this app</b>
                        <ul>
                            <li>Tap the share button <img width={20} src='/icons/ios-share.svg'/></li>
                            <li>Then tap <b>Add to Home Screen</b></li>
                        </ul>
                    </Typography.Text>,
                icon: <SmileTwoTone twoToneColor="#eb2f96" />,
                duration: 30,
                closeIcon: null,
                btn: 
                    <Space>
                        <Button onClick={onLaterClick}>Later</Button>
                    </Space>
            });
        }
        else {
            notiApi.open({
                placement: 'bottom',
                key: NOTI_KEY,
                message: <b>Install epar Connect Contractor</b>,
                description: 
                    <Typography.Text>
                        Install <b>epar Connect Contractor</b> on your home screen for quick and easy access when you are on the go.
                    </Typography.Text>,
                icon: <SmileTwoTone twoToneColor="#eb2f96" />,
                duration: 10,
                closeIcon: null,
                btn: <Space>
                    <Button onClick={onLaterClick}>Later</Button>
                    <Button onClick={onInstallClick} type='primary'>Install</Button>
                </Space>
            });
        }
    }, [notiApi, onInstallClick, onLaterClick]);

    useEffect(() => {
        if ((deferredPrompt || isIOS) && !appInstallRequested && !isInStandaloneMode()) {
            dispatch({ type: ACTIONS.APP_INSTALL_REQUESTED, payload: true });
            request();
        }
    }, [deferredPrompt, request, appInstallRequested, dispatch, isIOS]);

    useEffect(() => {
        const handleBeforeInstallPrompt = (e: Event) => {
            e.preventDefault();
            setDiferredPrompt(e);
        };

        window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

        return () => {
            window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
        };
    }, []);

    return (
        <>
            {contextHolder}
        </>
    );
}