import React, { useContext, useState } from 'react';
import { Button, Form, Input, message } from 'antd';
import { authContext } from '../../context/auth';
import { userSignIn } from './actions';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../constants';
import { isAuthenticatedUser } from '../../utils';
import { LockOutlined, UserOutlined } from '@ant-design/icons';

type LoginProps = {
    userName: string;
    password: string;
}

export default function Login() {
    const [loading, setLoading] = useState<boolean>(false);
    const { dispatch } = useContext(authContext);
    const navigate = useNavigate();

    const handleLogin = async ({ userName, password }: LoginProps) => {
        setLoading(true);
        message.loading('Please wait...', 10);
        const { response } = await userSignIn(userName, password, dispatch);
        const isSignedIn = await isAuthenticatedUser();

        if (isSignedIn) {
            message.destroy();
            message.success(response);
            navigate(ROUTES.REQUIREMENTS);
        }
        else {
            message.destroy();
            message.warning(response);
        }
        setLoading(false);
    }
    return (
        <div className='full-width content-centered full-height-vh' style={{}}>
            <div className='theme-max-width full-width content-centered login-form'>
                <div className='full-width pad-all margin'>
                    <img className='login-form-image' alt='contractor_logo' src='/logos/contractor.png' />
                    <Form size='large' className='full-width margin-top' onFinish={handleLogin}>
                        <Form.Item name='userName'>
                            <Input className='login-form-field' type='text' prefix={<UserOutlined className='login-form-field-icon ' />} placeholder='Username' />
                        </Form.Item>
                        <Form.Item name='password'>
                            <Input className='login-form-field' type='password' prefix={<LockOutlined className='login-form-field-icon ' />} placeholder='Password' />
                        </Form.Item>
                        <Form.Item>
                            <Button className='full-width login-button' loading={loading} type='primary' htmlType='submit'>Log in</Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </div>
    )
}