import { Dispatch, ReactElement, ReactNode, Reducer, createContext, useReducer } from "react";
import { Organization, User } from "../types";

export const ACTIONS = {
    USER_SIGNED_IN: 'user_signed_in',
    APP_INSTALL_REQUESTED: 'app_install_requested',
    ORG_LIST: 'org_list',
    SELECTED_ORG: 'selected_org',
    LOADING: 'loading',
    AUTHENTICATED: 'authenticated'
};

type StateType = {
    user: User | any,
    appInstallRequested: boolean,
    orgList: Organization[] | null | undefined,
    selectedOrg: Organization | any,
    loading: boolean,
    authenticated: boolean
};

export type ActionType = {
    type: string;
    payload: any;
};

export type DispatchType = Dispatch<ActionType>;

type ReduceType = Reducer<StateType, ActionType>;

type ContextType = {
    authState: StateType,
    dispatch: DispatchType
};

type PropsType = {
    children: ReactNode
};

const initialState: StateType = {
    user: undefined,
    appInstallRequested: false,
    orgList: undefined,
    selectedOrg: undefined,
    loading: true,
    authenticated: false,
};

export const authContext = createContext<ContextType>({ authState: initialState, dispatch: () => null });
const { Provider } = authContext;

export const AuthProvider = ({ children }: PropsType): ReactElement => {
    const [authState, dispatch] = useReducer<ReduceType>((state: StateType, action: ActionType): StateType => {
        const { type, payload } = action;
        switch (type) {
            case ACTIONS.USER_SIGNED_IN:
                return { ...state, user: payload };
            case ACTIONS.APP_INSTALL_REQUESTED:
                return { ...state, appInstallRequested: payload };
            case ACTIONS.ORG_LIST:
                return { ...state, orgList: payload };
            case ACTIONS.SELECTED_ORG:
                return { ...state, selectedOrg: payload };
            case ACTIONS.LOADING:
                return { ...state, loading: payload };
            case ACTIONS.AUTHENTICATED:
                return { ...state, authenticated: payload };
            default:
                return state;
        }
    }, initialState);

    return <Provider value={{ authState, dispatch }}>{children}</Provider>;
};

