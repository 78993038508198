import React, { useEffect, useState } from 'react';
import { isAuthenticatedUser } from './utils';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from './constants';

function PrivateRoute({ children }: any) {
    const [auth, setAuth] = useState(false);
    const navigate = useNavigate();

    const isAuthenticated = () => {
        setAuth(false);
        isAuthenticatedUser().then(res => {
            if (res) {
                setAuth(true);
            }
            else {
                redirectToLogin();
            }
        }).catch(err => {
            console.log(err);
            redirectToLogin();
        });
    }

    const redirectToLogin = () => {
        navigate(ROUTES.LOGIN);
    }

    useEffect(() => {
        isAuthenticated();
    }, []);

    return (
        <>{auth ? children : null}</>
    )
}

export default PrivateRoute;