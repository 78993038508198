import React, { useContext, useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { AppInstallRequester } from './components';
import { ACTIONS, authContext } from './context/auth';
import { isAuthenticatedUser } from './utils';
import { ROUTES } from './constants';
import { Login, MainView, SplashView } from './views';
import { initialData } from './views/Login/actions';
import { ConfigProvider } from 'antd';
import theme from './theme';
import PrivateRoute from './PrivateRoute';

function App() {
    const [authenticated, setAuthenticated] = useState(false);
    const { dispatch, authState } = useContext(authContext);
    const { loading } = authState;

    const load = async () => {
        const isAuthenticated = await isAuthenticatedUser();
        if (isAuthenticated) {
            initialData(dispatch);
        }
        setAuthenticated(isAuthenticated);
    }

    useEffect(() => {
        load();
    }, [dispatch]);

    return (
        <ConfigProvider theme={theme}>
            <AppInstallRequester />
            <BrowserRouter>
                <Routes>
                    <Route path="*" element={
                        loading && authenticated ? 
                        <SplashView /> :
                        <PrivateRoute>
                            <MainView />
                        </PrivateRoute>
                    } />
                    <Route path={ROUTES.LOGIN} element={<Login />} />
                </Routes>
            </BrowserRouter>
        </ConfigProvider>
    );
}

export default App;
