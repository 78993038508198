import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from './context/auth';
import { awsConfigInit } from './utils/aws';
import './styles/styles.scss';

await awsConfigInit();

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <AuthProvider>
        <App />
    </AuthProvider>
);

serviceWorkerRegistration.unregister();

reportWebVitals();
