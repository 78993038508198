import { useNavigate } from 'react-router-dom';
import QrScanner from './components/QrScanner';
import { ROUTES } from '../../constants';

export default function ScanQr() {
    const navigate = useNavigate();

    const onScanQr = (siteId: string) => {
        navigate(`${ROUTES.SITES}?siteId=${siteId}`);
    }
    return (
        <div className='full-width content-centered full-height scan-qr'>
            <QrScanner onScan={onScanQr}/>
        </div>
    )
}