export const ApiConfig = {
  basePath: 'https://www.api.contractor.eparconnectserver.com',
  connectApiBasePath: 'https://www.eparconnectserver.com',
  stage: 'prod',
  mapKey: 'AIzaSyDwZEQIMWIAJgZW5aeNNS9ZPvBvox-5jPc'
}

export const AwsConfig = {
  cognitoUserPoolId: 'us-west-2_VTc9GKx1u',
  userPoolWebClientId: '1c76oflphvsr2bgpif67alcuem',
  cognitoIdentityPoolId: 'us-west-2:e619f14a-56b5-4c1d-8539-ebf923f0c406',
  awsRegion: 'us-west-2',
}