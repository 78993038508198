import { Flex, Layout, Typography, Space } from 'antd';
import packageJson from '../../../package.json';
const { Content } = Layout;

export default function SplashView() {
    return (
        <Layout>
            <Content className='full-height-vh'>
                <div className='content-centered full-height'>
                    <div>
                        <Space.Compact direction='vertical'>
                            <Flex className='content-centered flex-column'>
                                <img className='splash-image' src='/logos/contractor-splash.png' alt='' />
                                <Typography.Text className='version'>Version : {packageJson.version}</Typography.Text>
                            </Flex>
                        </Space.Compact>
                    </div>
                </div>
            </Content>
        </Layout>
    )
}