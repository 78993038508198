import { ThemeConfig } from "antd";

const colors = {
    white : '#FEFEFE',
    red_color: '#F5222D',
    primary: '#218fa9',
    background_color: '#f1f1f1',
    dark_background: '#001923',
}

const theme: ThemeConfig = {
    token: {
        fontFamily: "Nunito",
        colorPrimary: colors.primary
    },
    components: {
        Modal: {
            fontFamily: "Nunito",
            colorPrimary: colors.primary
        },
        FloatButton: {
            colorPrimary: colors.dark_background
        }
    }
};

export default theme;