import proj4 from "proj4";
import { ConnectApi, ContractorApi, getApi, postApi } from "../../utils";
import { LOCATION_EVENT, USER_TYPE } from "../../constants";
import { Location } from "../../types";

export const isComplaintUser = async (orgId: string, ouId: string, userId: string, contractorId: string): Promise<any> => {
    const url = `/org_user_compliance?orgId=${orgId}&contractorId=${contractorId}&userId=${userId}&siteId=${ouId}`;
    const { userCompliance, contractorCompliance } = await getApi(ContractorApi.EXTERNAL_API, url);
    return userCompliance === 0 && contractorCompliance === 0;
}

export const getCheckedInDetails = async (userId: string): Promise<any> => {
    const url = `/orgs/mobile/user_checked_in/${userId}/${USER_TYPE.CONTRACTOR}`;
    return await getApi(ConnectApi.LOCATION_API, url);
}

export const checkInToSite = async (orgId: string, ouId: string, contractorId: string, userName: string, userId: string, longitude: number, latitude: number): Promise<any> => {
    const url = `/orgs/${orgId}/user_location_event`;
    const body = {
        userId: userId,
        userType: USER_TYPE.CONTRACTOR,
        location: `POINT(${longitude} ${latitude})`,
        eventType: LOCATION_EVENT.CHECK_IN,
        ouId: ouId,
        contractorId: contractorId,
        fullName: userName
    }
    return await postApi(ConnectApi.LOCATION_API, url, { body });
}

export const checkOutFromSite = async (orgId: string, ouId: string, contractorId: string, userName: string, userId: string, longitude: number, latitude: number): Promise<any> => {
    const url = `/orgs/${orgId}/user_location_event`;
    const body = {
        userId: userId,
        userType: USER_TYPE.CONTRACTOR,
        location: `POINT(${longitude} ${latitude})`,
        eventType: LOCATION_EVENT.CHECK_OUT,
        ouId: ouId,
        contractorId: contractorId,
        fullName: userName
    }
    return await postApi(ConnectApi.LOCATION_API, url, { body });
}

const getLocations = async (orgId: string): Promise<any> => {
    const url = `/orgs/${orgId}/contractor-org-locations`;
    return await getApi(ConnectApi.LOCATION_API, url);
}

export const getOrgLocations = async (orgIds: string[]) => {
    const orgLocations: Location[] = [];
    for (const orgId of orgIds) {
        const locations = await getLocations(orgId);
        orgLocations.push(...locations.data);
    };
    return orgLocations;
}

export const convertCoordinates = (locations: Location[]) => {
    const polyLocations: any = [];
    locations.map((location: Location) => {
        const geom = JSON.parse(location.geom);
        let coordinates = geom.geometry.coordinates[0];
        let cordArray: { lat: any; lng: any; }[] = [];
        coordinates.map((coordinate: any) => {
            let res = proj4('EPSG:3857', 'EPSG:4326', coordinate);
            cordArray.push({ lat: res[1], lng: res[0] });
            return;
        });
        polyLocations.push({
            locationId: location.location_id,
            paths: cordArray,
            fillColor: geom.properties.locationFillColor,
            ouName: location.ou_name,
            ouId: location.ou_id
        });
        return;
    });
    return polyLocations;
}
