export const ROUTES = {
    LOGIN: '/login',
    REQUIREMENTS: '/requirements',
    SITES: '/sites',
    SCAN_QR: '/qr-scan',
};

export const USER_ATTRIBUTES = [
    "email",
    "firstName",
    "lastName",
    "phoneNumber",
    "mobilePhone",
    "homePhone",
    "address",
    "name"
];

export const REQUIREMENT_CATEGORY = {
    DOC_UPLOAD: '1',
    DOC_REVIEW: '2',
    COURSE: '3'
};

export const REQUIREMENT_STATUS = {
    TO_DO : 0,
    PENDING : 50,
    COMPLETED : 100,
    REJECTED : -1,
    EXPIRED : -2,
}

export const LOGGED_USER = 'LOGGED_USER';

export const USER_TYPE = {
    CONNECT: 1,
    CONTRACTOR: 2
};

export const LOCATION_EVENT = {
    CHECK_IN: 0,
    CHECK_OUT: 1,
    ON_SITE: 2,
    OFF_SITE: 3
};
