import { List } from 'antd';
import { REQUIREMENT_CATEGORY, REQUIREMENT_STATUS } from '../../../constants';
import { CheckCircleFilled, ClockCircleFilled, CloseCircleFilled, ExclamationCircleFilled, QuestionCircleFilled } from '@ant-design/icons';

type RequirementTabBodyProps = {
    requirements?: any
}

export default function RequirementTabBody({ requirements }: RequirementTabBodyProps) {
    const key = requirements ? Object.keys(requirements) : [];

    const getCategory = (category: string) => {
        switch (category) {
            case REQUIREMENT_CATEGORY.COURSE:
                return 'Online Training';
            case REQUIREMENT_CATEGORY.DOC_REVIEW:
                return 'Document Review';
            case REQUIREMENT_CATEGORY.DOC_UPLOAD:
                return 'Document Upload';
            default:
                break;
        }
    }

    const getStatus = (status: number) => {
        switch (status) {
            case REQUIREMENT_STATUS.EXPIRED:
                return <CloseCircleFilled className='error-color icon' />;
            case REQUIREMENT_STATUS.REJECTED:
                return <CloseCircleFilled className='error-color icon' />;
            case REQUIREMENT_STATUS.TO_DO:
                return <ExclamationCircleFilled className='warn-color icon' />;
            case REQUIREMENT_STATUS.PENDING:
                return <ClockCircleFilled className='warn-color icon' />;
            case REQUIREMENT_STATUS.COMPLETED:
                return <CheckCircleFilled className='success-color icon' />;
            default:
                return <QuestionCircleFilled className='primary-color icon'/>;
        }
    }

    return (
        <div className='full-width'>
            {
                requirements && <List className='pad-hori-lg' itemLayout='horizontal' dataSource={requirements[key[0]]}
                    renderItem={(item: any) => (
                        <List.Item>
                            <List.Item.Meta title={item.title} description={getCategory(item.category)} />
                            <div>{getStatus(item.status)}</div>
                        </List.Item>
                    )}
                />
            }
        </div>
    )
}