import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { Button, Drawer, Menu, Select } from 'antd';
import { MenuOutlined, ContainerOutlined, EnvironmentOutlined, LogoutOutlined, QrcodeOutlined } from '@ant-design/icons';
import { MenuItem } from '../../types';
import { ROUTES } from '../../constants';
import { useLocation, useNavigate } from 'react-router-dom';
import packageJson from '../../../package.json';
import { userSignOut } from '../Login/actions';
import { ACTIONS, authContext } from '../../context/auth';

const { Option } = Select;

function getMenuItem(key: string, label: string, icon?: ReactElement): MenuItem {
    return { key, label, icon, };
}

const items: MenuItem[] = [
    getMenuItem(ROUTES.REQUIREMENTS, 'Requirements', <ContainerOutlined />),
    getMenuItem(ROUTES.SITES, 'Sites', <EnvironmentOutlined />),
    getMenuItem(ROUTES.LOGIN, 'Sign out', <LogoutOutlined />),
];

export default function SideNavigator() {
    const { dispatch, authState } = useContext(authContext);
    const { orgList, selectedOrg } = authState;
    const [isMenuOpen, setMenuOpen] = useState<boolean>(false);
    const [selectedMenu, setSelectedMenu] = useState<string>(ROUTES.REQUIREMENTS);
    const [userSelectedOrg, setUserSelectedOrg] = useState<string | undefined | null>(selectedOrg?.org_id);
    const { pathname } = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (pathname !== selectedMenu) {
            setSelectedMenu(pathname);
        }
    }, [pathname, selectedMenu]);

    const showDrawer = () => {
        setMenuOpen(true);
    };

    const closeDrawer = () => {
        setMenuOpen(false);
    };

    const onMenuSelect = async ({ key }: { key: string }) => {
        if (key === ROUTES.LOGIN) await userSignOut(dispatch);
        navigate(key);
        closeDrawer();
    }

    const onSelectOrg = (value: string) => {
        setUserSelectedOrg(value);
        dispatch({ type: ACTIONS.SELECTED_ORG, payload: {org_id:value} });
    }

    const drawer = isMenuOpen ? (
        <Drawer
            className='slide-nav-drawer'
            placement="top"
            onClose={closeDrawer}
            height={350}
            open={isMenuOpen}
            title={<img className='slide-nav-logo' alt='contractor_logo' src='/logos/contractor.png' />}
            footer={<p className='version'>{`Version : ${packageJson.version}`}</p>}>
            {userSelectedOrg && (
            <div style={{ padding: '16px' }}>
                <Select
                    placeholder="Select an organization"
                    className='slide-org-selector'
                    onChange={onSelectOrg}
                    value={userSelectedOrg}
                >
                    {orgList?.map((org) => (
                        <Option key={org.org_id} value={org.org_id}>
                            {org.org_name}
                        </Option>
                    ))}
                </Select>
            </div>
            )}
            <Menu
                mode="vertical"
                theme='dark'
                selectedKeys={[selectedMenu]}
                style={{ height: 40 }}
                items={items}
                onClick={onMenuSelect}
            >
            </Menu>
        </Drawer>
    ) : <></>;

    return (
        <>
            <Button type="text" icon={<MenuOutlined className='slide-nav-icon white-text' />} onClick={showDrawer} />
            {drawer}
        </>
    );
};