import { getCurrentUser } from 'aws-amplify/auth';

export const isAuthenticatedUser = async () => {
    try {
        await getCurrentUser();
        return true;
    }
    catch(error) {
        console.log(error);
        return false;
    }
}